
import {Company} from './company';
import {Machine} from "./machine";
import {Transportdagrapport} from "./transport-dagrapport";
import {Bedrijf} from "./E-digid/bedrijf";
import {Werf} from "./werf";

export class User {
  public _id: string;
  public id: string;
  public email: string;
  public name: string;
  public company_id: Company;
  telefoonNummer: string;
  public voornaam: string;
  public achternaam: string;
  public password: string;
  public password2: string;
  public gebruikersnaam: string;
  public bedrijfsnaam: string;
  public straat: string;
  public huisnummer: number;
  public gemeente: string;
  public aantal_werknemers: number;
  public isAdmin: boolean;
  public isAttached: boolean;
  public role: string;
  public isNoHelper: boolean;
  public functieNaam: string;
  public code: string;
  public firmaNaam: string;
  public voorkeurArbeiders: User[];
  public uurLoon: number;
  public kost: number;
  public minuten: number;
  uren: number;
  tempSortPriority: number;
  versionNumber: string;
  handtekening: string;
  latestAuthenticationEdigids: Date;
  twoFactorSecret: string;
  taal: string;
  hasViewExterneBedrijven: boolean;
  fcmToken: string;

  //tempvar
  days: [Days];
  selected: boolean;
  totaleWerkMinuten: number;
  werf: Werf;
  opmerkingAanArbeiders: string;

  //extra chauffeurvelden
  handtekeningFoto: string;
  nummerPlaat: string;
  externBedrijf: Bedrijf
  sharedCompanys: Company[];
  isTestObject: boolean;

  constructor(name: string, _id: string
    ) {
      this.name = name;
      this._id = _id;
    }
}

interface Days {
  day: string;
  werkMinuten: number;
  activiteitenPerWerf: {werf: Werf, activiteiten: Activiteiten[], startDate: Date, endDate: Date, werkMinuten: number}[];
  schaft: number;
  transportDagrapportId: string;
  startDate: Date;
  endDate: Date;
  userId: string;
}
interface Activiteiten{
  dagrapportId: string;
  activiteit: string;
  werkMinuten: number;
}
