import { Component } from '@angular/core';

import { MENU_ITEMS } from './pages-menu';
import {NbIconLibraries, NbMenuItem} from "@nebular/theme";
import {filter, interval, of, Subscription, switchMap} from "rxjs";
import {ApiService} from "../services/api.service";
import {Countsdata} from "../models/countsdata";
import {startWith} from "rxjs/operators";
import {NieuweCountService} from "../services/NieuweCountService";
import {Company} from "../models/company";
import {Werf} from "../models/werf";
import {FormService} from "../services/form.service";
// Extend NbMenuItem to include a custom disabled field
interface MyMenuItem extends NbMenuItem {
  disabled?: boolean;
}

@Component({
  selector: 'ngx-pages',
  styleUrls: ['pages.component.scss'],
  template: `
    <ngx-one-column-layout>
      <nb-menu *ngIf="sideBarIsLoaded" [items]="menu"></nb-menu>
      <router-outlet></router-outlet>
    </ngx-one-column-layout>
  `,
})
export class PagesComponent {
  count: number;
  private subscription: Subscription;
  menu: NbMenuItem[];
	countsData: Countsdata;
  company: Company;
  sideBarIsLoaded: boolean = false;
  private originalMenuItems: NbMenuItem[];

  constructor(private iconLibraries: NbIconLibraries, private apiService: ApiService, private countService: NieuweCountService  ) { }

  async ngOnInit()
  {
    this.originalMenuItems = this.deepCloneMenu(MENU_ITEMS);
    this.sideBarIsLoaded = false;
    this.iconLibraries.registerFontPack('fa', { packClass: 'fa', iconClassPrefix: 'fa'
    });
    this.iconLibraries.setDefaultPack('fa');

    //alle menus op hidden false zetten bij reload
    this.resetMenuVisibility();


    while(!this.apiService.hasLoaded){
      await this.delay(50);
    }
    if(!this.apiService.isDagboekToezichter){
      this.company = this.apiService.thisCompany;
    }
    if(this.apiService.isNotAllowedToLogin){
      this.sideBarIsLoaded = false;
      return;
    }
    const menuItem = this.menu.find(item => item.title === "Verlofaanvragen" );
        if(this.company != null && !this.company.heeftVerlofAanvragen){
          menuItem.hidden = true;
        }
        if(this.apiService.isOnderhoudstechnieker){
          this.menu.forEach(item => {
            if(item.title !== "Reparaties") {
              item.hidden = true;
            }
          });
        } else if(this.apiService.isMagazijnier){
          this.menu.forEach(item => {
            if(item.title !== "Taken magazijnier") {
              item.hidden = true;
            }
          });
        } else if(this.apiService.isOvamAdministrator || this.company?.hasOnlyAfvalstoffenView){
          this.menu.forEach(item => {
            if(item.title !== "Afvalstof formulieren OVAM" && item.title !== "Personeel") {
              item.hidden = true;
            }
          });
          this.menu.unshift({
            title: 'Account instellingen',
            link: '/pages/users/edituser/' + this.apiService.userId,
            icon: { icon: 'cog', pack: 'fa' },
          });
          this.menu.unshift({
            title: 'Algemeen',
            group:true
          });
        } else if(this.apiService.isDagboekToezichter){
          this.menu.forEach(item => {
            if(item.title !== "Dagboek & staat der verlet") {
              item.hidden = true;
            }
          })
          //shift new group nbmenu
          this.menu.unshift({
            title: 'Algemeen',
            group: true
          });

          //push account settings
          this.menu.push({
            title: 'Account instellingen',
            link: '/pages/users/edittoezichter/' + this.apiService.userId,
            icon: { icon: 'cog', pack: 'fa' },
          });
        }
        this.sideBarIsLoaded = true;

    if(!this.apiService.isOnderhoudstechnieker && !this.apiService.isMagazijnier && !this.apiService.isOvamAdministrator && !this.company?.hasOnlyAfvalstoffenView && !this.apiService.isDagboekToezichter){
      this.subscription = interval(1800000) // every 30 minutes (1800000 milliseconds)
        .pipe(
          filter(() => document.visibilityState === 'visible'), // Only proceed if the document is visible
          startWith(0), // Trigger immediately at start
          switchMap(() => {
            // Only call the API when the document is visible
            if (document.visibilityState === 'visible') {
              return this.apiService.getUneditedObjectsCount();
            } else {
              // If not visible, return an observable that doesn't emit anything
              return of(null);
            }
          })
        )
        .subscribe(count => {
          if (count !== null) {
            this.countsData = count as Countsdata;
            this.countService.updateCounts(this.countsData);
          }
        });

      //subscribe voor manuele count update
      this.countService.countsData$.subscribe(count => {
        // Your menu update logic here
        if(count != null){
          const menuItem2 = this.menu.find(item => item.title === "Meerwerken");
          if (count.meerWerken > 0) {
            menuItem2.badge = {
              text: count.meerWerken.toString(),
              status: 'info',
            };
          } else {
            menuItem2.badge = null;
          }
          const menuItem3 = this.menu.find(item => item.title === "Verlofaanvragen");
          if (count.verlofAanvragen > 0) {
            menuItem3.badge = {
              text: count.verlofAanvragen.toString(),
              status: 'info',
            };
          } else {
            menuItem3.badge = null;
          }
          const menuItem4 = this.menu.find(item => item.title === "Schademeldingen");
          if (count.schadeMeldingen > 0) {
            menuItem4.badge = {
              text: count.schadeMeldingen.toString(),
              status: 'info',
            };
          } else {
            menuItem4.badge = null;
          }
        }
      });
    }
  }
  delay(timeInMillis: number): Promise<void> {
    return new Promise((resolve) => setTimeout(() => resolve(), timeInMillis));
  }

  private resetMenuVisibility() {
    // Reset menu using the original configuration
    this.menu = this.deepCloneMenu(this.originalMenuItems);

    // Ensure all items are visible
    this.menu.forEach(item => {
      item.hidden = false;
      if (item.children) {
        item.children.forEach(child => {
          child.hidden = false;
        });
      }
    });
  }
  private deepCloneMenu(items: NbMenuItem[]): NbMenuItem[] {
    return items.map(item => ({
      ...item,
      children: item.children ? this.deepCloneMenu(item.children) : undefined,
    }));
  }
}
