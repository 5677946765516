import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {NbToastrService} from "@nebular/theme";
import {ApiService} from "../../services/api.service";
import {FormService} from "../../services/form.service";
import {MatDialog} from "@angular/material/dialog";
interface SetupStep {
  title: string;
  subtitle?: string;
  modules?: string[];
  content?: string;
  imageInTitle?: boolean;
  image1?: string;
  image2?: string;
  image3?: string;
  bullets?: string[];
}

@Component({
  selector: 'aanmaakvolgorde-popup-dialog',
  templateUrl: './aanmaakvolgorde-popup-dialog.component.html',
  styleUrls: ['./aanmaakvolgorde-popup-dialog.component.scss']
})
export class AanmaakvolgordePopupDialogComponent implements OnInit {
  text: any;
  steps: SetupStep[] = [
    {
      title: '1. Creëer machines',
      modules: ['DAGRAPPORTEN', 'DAGBOEK DER WERKEN', 'TRANSPORT'],
      image1: 'assets/algemene-handleiding/materieelEnWerven.png',
      image2: 'assets/algemene-handleiding/machines.png'
    },
    {
      title: '2. Creëer materialen',
      modules: ['DAGRAPPORTEN', 'DAGBOEK DER WERKEN', 'TRANSPORT'],
      image1: 'assets/algemene-handleiding/materieelEnWerven.png',
      image2: 'assets/algemene-handleiding/materialen.png'
    },
    {
      title: '3. Voeg activiteiten toe',
      modules: ['DAGRAPPORTEN'],
      image1: 'assets/algemene-handleiding/materieelEnWerven.png',
      image2: 'assets/algemene-handleiding/activiteiten.png'
    },
    {
      title: '4. Stel de standaard dagrapportinstellingen in',
      modules: ['DAGRAPPORTEN'],
      bullets: [
        'Standaard start- en einduur: deze uren worden als standaard overgenomen bij het aanmaken van een werf',
        'De mogelijke eenheden',
        'Uur popup melding mobiele app'
      ],
      image1: 'assets/algemene-handleiding/dagrapportInstellingen.png',
    },
    {
      title: '5. Creëer accounts of laat werknemers zichzelf registreren in de mobiele app met jullie bedrijfscode',
      modules: ['DAGRAPPORTEN', 'DAGBOEK DER WERKEN', 'TRANSPORT', 'AFVALSTOFFEN OVAM', 'WERFPLANNING', 'REPARATIES'],
      image1: 'assets/algemene-handleiding/personeel.png',
      image2: 'assets/algemene-handleiding/personeel2.png',
      image3: 'assets/algemene-handleiding/personeel3.png'
    },
    {
      title: '6. Creëer werven',
      modules: ['DAGRAPPORTEN', 'DAGBOEK DER WERKEN', 'TRANSPORT', 'AFVALSTOFFEN OVAM', 'WERFPLANNING', 'REPARATIES'],
      image1: 'assets/algemene-handleiding/materieelEnWerven.png',
      image2: 'assets/algemene-handleiding/werven.png'
    },
    {
      title: '7. Creëer laad- en losplaatsen',
      modules: ['TRANSPORT'],
      image1: 'assets/algemene-handleiding/materieelEnWerven.png',
      image2: 'assets/algemene-handleiding/laadlosplaatsen.png'
    },
    {
      title: '8. Het systeem is geconfigureerd en klaar voor gebruik',
      bullets: [
        'Mobiele app: Selux Werklogger',
        'iOS (iPhone & iPad)',
        'Android (smartphones & tablets)'
      ],
      imageInTitle:  true,
    }
  ];
  constructor(
    private dialog: MatDialog,
    private apiService: ApiService,
    private formService: FormService,
    private router: Router,
    private toastrService: NbToastrService
  ) { }

  ngOnInit(): void {
  }



  closeDialog(){
    this.dialog.closeAll();
  }
  submit() {
    let text = this.text;
    this.apiService.sendComment(text).subscribe(x => {
      this.toastrService.success('Uw opmerking is verstuurd! U krijgt eventueel een antwoord terug via email', 'Succes!');
      this.dialog.closeAll();
    })
  }


  getModuleClass(module: string): string {
    const moduleClasses = {
      'DAGRAPPORTEN': 'module-box--blue',
      'DAGBOEK DER WERKEN': 'module-box--green',
      'WERFPLANNING': 'module-box--purple',
      'TRANSPORT': 'module-box--orange',
      'AFVALSTOFFEN OVAM': 'module-box--emerald',
      'REPARATIES': 'module-box--red'
    };
    return moduleClasses[module] || '';
  }

  getModuleIcon(module: string): string {
    const moduleIcons = {
      'DAGRAPPORTEN': 'fa-solid fa-person-digging',
      'DAGBOEK DER WERKEN': 'fa-solid fa-book-open',
      'WERFPLANNING': 'fa-solid fa-calendar-check',
      'TRANSPORT': 'fa-solid fa-truck',
      'AFVALSTOFFEN OVAM': 'fa-solid fa-recycle',
      'REPARATIES': 'fa-solid fa-screwdriver-wrench'
    };
    return moduleIcons[module] || '';
  }
}
