import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  ViewChildren,
  QueryList
} from '@angular/core';
import {NbTabsetComponent} from '@nebular/theme';
import {ApiService} from "../../services/api.service";
import {forkJoin, Observable, of} from "rxjs";
import {Dagrapport} from "../../models/dagrapport";
import {Transportdagrapport} from "../../models/transport-dagrapport";
import {User} from "../../models/user";
import {Router} from "@angular/router";
import {FormService} from "../../services/form.service";
import {map} from "rxjs/operators";
import moment from "moment";

@Component({
  selector: 'ngx-aanwezigheidslijst',
  templateUrl: './aanwezigheidslijst.component.html',
  styleUrls: ['./aanwezigheidslijst.component.scss', '../../styles/item-table.scss']
})
export class AanwezigheidslijstComponent implements OnInit {
  @ViewChild('autoInput') input;
  @ViewChild('scrollContainer') scrollContainer: ElementRef;
  @ViewChildren('userElement') userElements: QueryList<ElementRef>;
  @ViewChild('tabset') tabset: NbTabsetComponent;

  filterItemsNaam: User[] = [];
  observableFilteredItems$: Observable<string[]>;
  public isLoaded: boolean;
  public dagrapporten: Dagrapport[];
  public transportDagrapporten: Transportdagrapport[];
  currentWeekIndex: number;
  huidigeWeekIndex: number;
  currentWeek: any;
  weeks: any[];
  arbeiders:User[];
  chauffeurs:User[];
  onderaannemers:User[];
  filteredArray: User[];
  allUsers: User[];


  constructor(
    private apiService: ApiService,
    private router: Router,
    public formService: FormService,
  ) {}

  async ngOnInit() {
    // Get current date and find Monday of current week
    let now = new Date();
    let daysToSubtract = (now.getDay() + 6) % 7;

    let startOfWeek = new Date(now);
    startOfWeek.setDate(now.getDate() - daysToSubtract);
    startOfWeek.setHours(0, 0, 0, 0);

    let endOfWeek = new Date(startOfWeek);
    endOfWeek.setDate(startOfWeek.getDate() + 4);
    endOfWeek.setHours(23, 59, 59, 999);

    // Get all weeks for the year
    this.weeks = this.getWeeksInYear(startOfWeek.getFullYear());

    // Helper function to compare dates ignoring time
    const isSameDate = (date1: Date, date2: Date) => {
      return date1.getFullYear() === date2.getFullYear() &&
        date1.getMonth() === date2.getMonth() &&
        date1.getDate() === date2.getDate();
    };

    // Find current week index by comparing dates without time
    this.currentWeekIndex = this.weeks.findIndex(week => isSameDate(week.start, startOfWeek));
    this.huidigeWeekIndex = this.currentWeekIndex;

    // Find current week
    this.currentWeek = this.weeks[this.currentWeekIndex];

    // Set default value if not already set
    this.formService.aanwezigheidsLijstChosenSoort =
      this.formService.aanwezigheidsLijstChosenSoort || 'Arbeiders';

    // Handle previous week selection if exists
    if (this.formService.previousWeekIndexAanwezigsheidslijst !== undefined) {
      this.currentWeekIndex = this.formService.previousWeekIndexAanwezigsheidslijst;
      this.currentWeek = this.weeks[this.currentWeekIndex];
    }

    // Load data
    this.loadUsers();
    await this.getDagrapportenOfWeek(this.currentWeek.start, this.currentWeek.end);

    this.isLoaded = true;

    // Scroll to previous user after a short delay
    setTimeout(() => {
      this.scrollToPreviousUser();
    }, 100);
  }



  async loadUsers() {
          this.apiService.getUsers().subscribe(
            (users: User[]) => {
              const filteredUsers = users.filter(
                (x) => (x.role === '59cf78e883680012b0438501' || x.role === '59cf78e883680012b0438502' || x.role === '59cf78e883680012b0438504' ) && x.name !== 'bjorn massoels'
              );
              this.arbeiders = filteredUsers.filter(x => (x.role === '59cf78e883680012b0438501' || x.role === '59cf78e883680012b0438504') && x.functieNaam !== 'Onderaannemer');
              this.chauffeurs = filteredUsers.filter(x => x.role === '59cf78e883680012b0438502');
              this.onderaannemers = filteredUsers.filter(x => x.functieNaam === 'Onderaannemer');
              this.setDatesOnUsers();
        });
  }

  setDatesOnUsers(){
    for(let user of this.arbeiders){
      // @ts-ignore
      user.days = [];
      user.totaleWerkMinuten = 0;
      for(let day of this.currentWeek.days){
        // @ts-ignore
        user.days.push({userId: user._id, day: day, werkMinuten: 0, activiteitenPerWerf: []});
      }
    }
    for(let user of this.chauffeurs){
      // @ts-ignore
      user.days = [];
      user.totaleWerkMinuten = 0;
      for(let day of this.currentWeek.days){
        // @ts-ignore
        user.days.push({userId: user._id, day: day, werkMinuten: 0, activiteitenPerWerf: []});
      }
    }
    for(let user of this.onderaannemers){
      // @ts-ignore
      user.days = [];
      user.totaleWerkMinuten = 0;
      for(let day of this.currentWeek.days){
        // @ts-ignore
        user.days.push({userId: user._id, day: day, werkMinuten: 0, activiteitenPerWerf: []});
      }
    }
    this.allUsers = [...this.arbeiders, ...this.chauffeurs, ...this.onderaannemers];
    this.filterItemsNaam = this.arbeiders;
    this.observableFilteredItems$ = of(this.arbeiders.map(x => x.name));
  }

  private scrollToPreviousUser() {
    if (this.formService.previousUserIdAanwezigheidslijst) {
      const userId = this.formService.previousUserIdAanwezigheidslijst;
      const userIndex = this.filteredArray.findIndex(user => user._id === userId);
      if (userIndex !== -1) {
        this.scrollToUser(userIndex);
      }
    }
  }

  getDagrapportenOfWeek(date1: Date, date2: Date){
    const dagrapporten$ = this.apiService.getDagrapportenExcel(date1.toString(), date2.toString(), 'geen', 'geen', 'geen');
    const transportDagrapporten$ = this.apiService.getTransportDagrapportenExcel(date1.toString(), date2.toString(), 'geen', 'geen', 'geen');

    return new Promise<any>((resolve, reject) => {
      forkJoin([dagrapporten$, transportDagrapporten$]).subscribe(async ([dagrapportenResult, transportDagrapportenResult]) => {
        this.dagrapporten = dagrapportenResult as Dagrapport[];
        this.transportDagrapporten = transportDagrapportenResult as Transportdagrapport[];


        while(this.allUsers == null){
          await this.delay(50);
        }
        if(this.dagrapporten != null && this.dagrapporten.length !== 0){
          for (let dagrapport of this.dagrapporten) {
            dagrapport.date = new Date(dagrapport.date);
            for (let werk of dagrapport.werken) {
              let werkMinuten;
              if (werk.startDate != null) {
                werk.startDate = new Date(werk.startDate);
                werk.endDate = new Date(werk.endDate);
                werk.startDate.setSeconds(0, 0);
                werk.endDate.setSeconds(0, 0);
                werkMinuten = this.calculateWorkMinutes(werk.startDate, werk.endDate);
              }
              if (werk.werkzaamheid != null && werk.startDate != null) {
                let werkzaamheid;
                if (werk.werkzaamheid.naam === 'Andere') {
                  werkzaamheid = werk.anderWerk + ' ';
                } else if (werk.werkzaamheid.naam === 'Meerwerk') {
                  werkzaamheid = 'Meerwerk ' + werk.meerWerk + ' ';
                } else if (werk.subActiviteit != null && werk.subActiviteit !== '') {
                  werkzaamheid = werk.werkzaamheid.naam + ' ' + werk.subActiviteit + ' ';
                } else {
                  werkzaamheid = werk.werkzaamheid.naam + ' ';
                }



                if (werk.machinisten != null && werk.machinisten.length !== 0) {
                  for (let machinist of werk.machinisten) {
                    let machinistIndex = this.allUsers.findIndex(x => x._id === machinist._id);
                    if (machinistIndex != null && machinistIndex !== -1) {
                      let dayIndex = this.allUsers[machinistIndex].days?.findIndex(x => x.day === werk.startDate.toISOString().split('T')[0])
                      if(dayIndex !== -1){
                        this.allUsers[machinistIndex].days[dayIndex].werkMinuten += werkMinuten;
                        this.allUsers[machinistIndex].totaleWerkMinuten += werkMinuten;
                        if(dagrapport.schaft != null && dagrapport.schaft !== 0){
                          this.allUsers[machinistIndex].days[dayIndex].schaft = dagrapport.schaft;
                        }
                        if(this.allUsers[machinistIndex].days[dayIndex].startDate == null || this.allUsers[machinistIndex].days[dayIndex].startDate.getTime() > werk.startDate.getTime()){
                          this.allUsers[machinistIndex].days[dayIndex].startDate = werk.startDate;
                        }
                        if(this.allUsers[machinistIndex].days[dayIndex].endDate == null || this.allUsers[machinistIndex].days[dayIndex].endDate.getTime() < werk.endDate.getTime()){
                          this.allUsers[machinistIndex].days[dayIndex].endDate = werk.endDate;
                        }
                        if(this.allUsers[machinistIndex].days[dayIndex].activiteitenPerWerf.find(x => x.werf._id === dagrapport.werf._id) == null){
                          this.allUsers[machinistIndex].days[dayIndex].activiteitenPerWerf.push({werf: dagrapport.werf, activiteiten: [], startDate: null, endDate: null, werkMinuten: 0});
                        }
                        let werfIndex = this.allUsers[machinistIndex].days[dayIndex].activiteitenPerWerf.findIndex(x => x.werf._id === dagrapport.werf._id);
                        this.allUsers[machinistIndex].days[dayIndex].activiteitenPerWerf[werfIndex].activiteiten.push({activiteit: werkzaamheid, werkMinuten: werkMinuten, dagrapportId: dagrapport._id});
                        if(this.allUsers[machinistIndex].days[dayIndex].activiteitenPerWerf[werfIndex].startDate == null || this.allUsers[machinistIndex].days[dayIndex].activiteitenPerWerf[werfIndex].startDate.getTime() > werk.startDate.getTime()){
                          this.allUsers[machinistIndex].days[dayIndex].activiteitenPerWerf[werfIndex].startDate = werk.startDate;
                        }
                        if(this.allUsers[machinistIndex].days[dayIndex].activiteitenPerWerf[werfIndex].endDate == null || this.allUsers[machinistIndex].days[dayIndex].activiteitenPerWerf[werfIndex].endDate.getTime() < werk.endDate.getTime()){
                          this.allUsers[machinistIndex].days[dayIndex].activiteitenPerWerf[werfIndex].endDate = werk.endDate;
                        }
                        this.allUsers[machinistIndex].days[dayIndex].activiteitenPerWerf[werfIndex].werkMinuten += werkMinuten;
                      }
                    }
                  }
                }
                if (werk.handwerkers != null && werk.handwerkers.length !== 0) {
                  for (let arbeider of werk.handwerkers) {
                    let arbeiderIndex = this.allUsers.findIndex(x => x._id === arbeider._id);
                    if (arbeiderIndex != null && arbeiderIndex !== -1) {
                      let dayIndex = this.allUsers[arbeiderIndex].days.findIndex(x => x.day === werk.startDate.toISOString().split('T')[0]);
                      if(this.allUsers[arbeiderIndex].days[dayIndex] == null){
                        console.log(arbeider)
                      }
                      this.allUsers[arbeiderIndex].days[dayIndex].werkMinuten += werkMinuten;
                      this.allUsers[arbeiderIndex].totaleWerkMinuten += werkMinuten;
                      if(dagrapport.schaft != null && dagrapport.schaft !== 0){
                        this.allUsers[arbeiderIndex].days[dayIndex].schaft = dagrapport.schaft;
                      }
                      if(this.allUsers[arbeiderIndex].days[dayIndex].startDate == null || this.allUsers[arbeiderIndex].days[dayIndex].startDate.getTime() > werk.startDate.getTime()){
                        this.allUsers[arbeiderIndex].days[dayIndex].startDate = werk.startDate;
                      }
                      if(this.allUsers[arbeiderIndex].days[dayIndex].endDate == null || this.allUsers[arbeiderIndex].days[dayIndex].endDate.getTime() < werk.endDate.getTime()){
                        this.allUsers[arbeiderIndex].days[dayIndex].endDate = werk.endDate;
                      }
                      if(this.allUsers[arbeiderIndex].days[dayIndex].activiteitenPerWerf.find(x => x.werf._id === dagrapport.werf._id) == null){
                        this.allUsers[arbeiderIndex].days[dayIndex].activiteitenPerWerf.push({werf: dagrapport.werf, activiteiten: [], startDate: null, endDate: null, werkMinuten: 0});
                      }
                      let werfIndex = this.allUsers[arbeiderIndex].days[dayIndex].activiteitenPerWerf.findIndex(x => x.werf._id === dagrapport.werf._id);
                      this.allUsers[arbeiderIndex].days[dayIndex].activiteitenPerWerf[werfIndex].activiteiten.push({activiteit: werkzaamheid, werkMinuten: werkMinuten, dagrapportId: dagrapport._id});
                      if(this.allUsers[arbeiderIndex].days[dayIndex].activiteitenPerWerf[werfIndex].startDate == null || this.allUsers[arbeiderIndex].days[dayIndex].activiteitenPerWerf[werfIndex].startDate.getTime() > werk.startDate.getTime()){
                        this.allUsers[arbeiderIndex].days[dayIndex].activiteitenPerWerf[werfIndex].startDate = werk.startDate;
                      }
                      if(this.allUsers[arbeiderIndex].days[dayIndex].activiteitenPerWerf[werfIndex].endDate == null || this.allUsers[arbeiderIndex].days[dayIndex].activiteitenPerWerf[werfIndex].endDate.getTime() < werk.endDate.getTime()){
                        this.allUsers[arbeiderIndex].days[dayIndex].activiteitenPerWerf[werfIndex].endDate = werk.endDate;
                      }
                      this.allUsers[arbeiderIndex].days[dayIndex].activiteitenPerWerf[werfIndex].werkMinuten += werkMinuten;
                    }
                  }
                }
                if (werk.onderaannemers != null && werk.onderaannemers.length !== 0) {
                  for (let onderaannemer of werk.onderaannemers) {
                    let onderaannemerIndex = this.onderaannemers.findIndex(x => x._id === onderaannemer._id);
                    if (onderaannemerIndex != null && onderaannemerIndex !== -1) {
                      let dayIndex = this.onderaannemers[onderaannemerIndex].days.findIndex(x => x.day === werk.startDate.toISOString().split('T')[0])
                      this.onderaannemers[onderaannemerIndex].days[dayIndex].werkMinuten += werkMinuten;
                      this.onderaannemers[onderaannemerIndex].totaleWerkMinuten += werkMinuten;

                      if(dagrapport.schaft != null && dagrapport.schaft !== 0){
                        this.onderaannemers[onderaannemerIndex].days[dayIndex].schaft = dagrapport.schaft;
                      }
                      if(this.onderaannemers[onderaannemerIndex].days[dayIndex].startDate == null || this.onderaannemers[onderaannemerIndex].days[dayIndex].startDate.getTime() > werk.startDate.getTime()){
                        this.onderaannemers[onderaannemerIndex].days[dayIndex].startDate = werk.startDate;
                      }
                      if(this.onderaannemers[onderaannemerIndex].days[dayIndex].endDate == null || this.onderaannemers[onderaannemerIndex].days[dayIndex].endDate.getTime() < werk.endDate.getTime()){
                        this.onderaannemers[onderaannemerIndex].days[dayIndex].endDate = werk.endDate;
                      }
                      if(this.onderaannemers[onderaannemerIndex].days[dayIndex].activiteitenPerWerf.find(x => x.werf._id === dagrapport.werf._id) == null){
                        this.onderaannemers[onderaannemerIndex].days[dayIndex].activiteitenPerWerf.push({werf: dagrapport.werf, activiteiten: [], startDate: null, endDate: null, werkMinuten: 0});
                      }
                      let werfIndex = this.onderaannemers[onderaannemerIndex].days[dayIndex].activiteitenPerWerf.findIndex(x => x.werf._id === dagrapport.werf._id);
                      this.onderaannemers[onderaannemerIndex].days[dayIndex].activiteitenPerWerf[werfIndex].activiteiten.push({activiteit: werkzaamheid, werkMinuten: werkMinuten, dagrapportId: dagrapport._id});
                      if(this.onderaannemers[onderaannemerIndex].days[dayIndex].activiteitenPerWerf[werfIndex].startDate == null || this.onderaannemers[onderaannemerIndex].days[dayIndex].activiteitenPerWerf[werfIndex].startDate.getTime() > werk.startDate.getTime()){
                        this.onderaannemers[onderaannemerIndex].days[dayIndex].activiteitenPerWerf[werfIndex].startDate = werk.startDate;
                      }
                      if(this.onderaannemers[onderaannemerIndex].days[dayIndex].activiteitenPerWerf[werfIndex].endDate == null || this.onderaannemers[onderaannemerIndex].days[dayIndex].activiteitenPerWerf[werfIndex].endDate.getTime() < werk.endDate.getTime()){
                        this.onderaannemers[onderaannemerIndex].days[dayIndex].activiteitenPerWerf[werfIndex].endDate = werk.endDate;
                      }
                      this.onderaannemers[onderaannemerIndex].days[dayIndex].activiteitenPerWerf[werfIndex].werkMinuten += werkMinuten;
                    }
                  }
                }
              }
            }
          }
        }

        this.transportDagrapporten = transportDagrapportenResult as Transportdagrapport[];
        if (this.transportDagrapporten != null && this.transportDagrapporten.length !== 0) {
          for (let transportdagrapport of this.transportDagrapporten) {
            transportdagrapport.date = new Date(transportdagrapport.date);
            let userIndex = this.chauffeurs.findIndex(x => x._id === transportdagrapport.user_id?._id);

            for (let werk of transportdagrapport.transportwerken) {
              if (werk.dateLaadplaats != null) {
                werk.dateLaadplaats = new Date(werk.dateLaadplaats);
                werk.dateLosplaats = new Date(werk.dateLosplaats);
              }
              if (userIndex != null && userIndex !== -1) {
                let dayIndex = this.chauffeurs[userIndex].days.findIndex(x => x.day === werk.dateLosplaats.toISOString().split('T')[0])
                if (dayIndex != null) {
                  if(transportdagrapport.schaft != null && transportdagrapport.schaft !== 0){
                    this.chauffeurs[userIndex].days[dayIndex].schaft = transportdagrapport.schaft;
                  }
                  let werkMinuten = this.calculateWorkMinutes(werk.dateLaadplaats, werk.dateLosplaats);

                  this.chauffeurs[userIndex].days[dayIndex].werkMinuten += werkMinuten;
                  this.chauffeurs[userIndex].totaleWerkMinuten += werkMinuten;

                  if(this.chauffeurs[userIndex].days[dayIndex].startDate == null || this.chauffeurs[userIndex].days[dayIndex].startDate.getTime() > werk.dateLaadplaats.getTime()){
                    this.chauffeurs[userIndex].days[dayIndex].startDate = werk.dateLaadplaats;
                  }
                  if(this.chauffeurs[userIndex].days[dayIndex].endDate == null || this.chauffeurs[userIndex].days[dayIndex].endDate.getTime() < werk.dateLosplaats.getTime()){
                    this.chauffeurs[userIndex].days[dayIndex].endDate = werk.dateLosplaats;
                  }
                  this.chauffeurs[userIndex].days[dayIndex].transportDagrapportId = transportdagrapport._id;
                  if(this.chauffeurs[userIndex].days[dayIndex].activiteitenPerWerf.find(x => x.werf._id === werk.werf._id) == null){
                    this.chauffeurs[userIndex].days[dayIndex].activiteitenPerWerf.push({werf: werk.werf, activiteiten: [], startDate: null, endDate: null, werkMinuten: 0});
                  }
                  let activiteit = werk.laadplaats.naam + ' - ' + werk.losplaats.naam;
                  let werfIndex = this.chauffeurs[userIndex].days[dayIndex].activiteitenPerWerf.findIndex(x => x.werf._id === werk.werf._id);
                  this.chauffeurs[userIndex].days[dayIndex].activiteitenPerWerf[werfIndex].activiteiten.push({activiteit: activiteit, werkMinuten: this.calculateWorkMinutes(werk.dateLaadplaats, werk.dateLosplaats), dagrapportId: null});
                  if(this.chauffeurs[userIndex].days[dayIndex].activiteitenPerWerf[werfIndex].startDate == null || this.chauffeurs[userIndex].days[dayIndex].activiteitenPerWerf[werfIndex].startDate.getTime() > werk.dateLaadplaats.getTime()){
                    this.chauffeurs[userIndex].days[dayIndex].activiteitenPerWerf[werfIndex].startDate = werk.dateLaadplaats;
                  }
                  if(this.chauffeurs[userIndex].days[dayIndex].activiteitenPerWerf[werfIndex].endDate == null || this.chauffeurs[userIndex].days[dayIndex].activiteitenPerWerf[werfIndex].endDate.getTime() < werk.dateLosplaats.getTime()){
                    this.chauffeurs[userIndex].days[dayIndex].activiteitenPerWerf[werfIndex].endDate = werk.dateLosplaats;
                  }
                  this.chauffeurs[userIndex].days[dayIndex].activiteitenPerWerf[werfIndex].werkMinuten += this.calculateWorkMinutes(werk.dateLaadplaats, werk.dateLosplaats);
                }
              } else {

              }
            }
          }
        }

        if(this.formService.aanwezigheidsLijstChosenSoort === 'Arbeiders'){
          this.filteredArray = this.arbeiders;
        } else if(this.formService.aanwezigheidsLijstChosenSoort === 'Chauffeurs'){
          this.filteredArray = this.chauffeurs;
        } else if(this.formService.aanwezigheidsLijstChosenSoort === 'Onderaannemers'){
          this.filteredArray = this.onderaannemers;
        }
        resolve(this.dagrapporten);
      },
        (error) => {
          // If there's an error, reject the promise
          reject(error);
        });
    });
  }
  getWeeksInYear(year: number) {
    const weeks = [];

    // Helper function to get ISO week number
    const getISOWeek = (date: Date) => {
      const target = new Date(date.valueOf());
      const dayNr = (date.getDay() + 6) % 7;
      target.setDate(target.getDate() - dayNr + 3);
      const firstThursday = target.valueOf();
      target.setMonth(0, 1);
      if (target.getDay() !== 4) {
        target.setMonth(0, 1 + ((4 - target.getDay()) + 7) % 7);
      }
      return {
        week: 1 + Math.ceil((firstThursday - target.valueOf()) / 604800000),
        year: target.getFullYear()
      };
    };

    // Start from first Monday of the year
    let currentDate = new Date(year, 0, 1);
    while (currentDate.getDay() !== 1) {
      currentDate.setDate(currentDate.getDate() + 1);
    }

    const lastDayOfYear = new Date(year, 11, 31);

    // Generate weeks until we've passed the last day of the year
    while (currentDate <= lastDayOfYear) {
      const startOfWeek = new Date(currentDate);
      startOfWeek.setHours(4, 0, 0, 0);

      const endOfWeek = new Date(currentDate);
      endOfWeek.setDate(startOfWeek.getDate() + 4); // Always set to Friday
      endOfWeek.setHours(21, 59, 59, 999);

      // Generate the days array (Monday through Friday)
      const days: string[] = [];
      for (let i = 0; i < 5; i++) {
        const dayDate = new Date(startOfWeek);
        dayDate.setDate(startOfWeek.getDate() + i);
        days.push(dayDate.toISOString().split('T')[0]);
      }

      // Get ISO week info
      const isoWeek = getISOWeek(startOfWeek);

      weeks.push({
        start: startOfWeek,
        end: endOfWeek,
        days,
        weekNumber: isoWeek.week,
        weekYear: isoWeek.year,
        totalWeeks: 52
      });

      // Move to next Monday
      currentDate.setDate(currentDate.getDate() + 7);
    }

    // Add remaining days of December if they exist
    const lastWeek = weeks[weeks.length - 1];
    const lastDayInWeeks = new Date(lastWeek.days[lastWeek.days.length - 1]);

    if (lastDayInWeeks < lastDayOfYear) {
      const remainingDays: string[] = [];
      let currentDay = new Date(lastDayInWeeks);
      currentDay.setDate(currentDay.getDate() + 1);

      while (currentDay <= lastDayOfYear) {
        if (currentDay.getDay() !== 0 && currentDay.getDay() !== 6) {
          remainingDays.push(currentDay.toISOString().split('T')[0]);
        }
        currentDay.setDate(currentDay.getDate() + 1);
      }

      if (remainingDays.length > 0) {
        const startDate = new Date(remainingDays[0]);
        startDate.setHours(0, 0, 0, 0);
        const endDate = new Date(remainingDays[remainingDays.length - 1]);
        endDate.setHours(23, 59, 59, 999);

        weeks.push({
          start: startDate,
          end: endDate,
          days: remainingDays,
          weekNumber: getISOWeek(startDate).week,
          weekYear: getISOWeek(startDate).year,
          totalWeeks: 52
        });
      }
    }

    return weeks;
  }

  convertDate(date: Date){
    return ('0' + date.getDate()).slice(-2) + '/' + ('0' + (date.getMonth()+1)).slice(-2) + '/' + date.getFullYear();
  }

  convertHours(date: Date){
    return ('0' + date.getHours()).slice(-2) + ':' + ('0' + date.getMinutes()).slice(-2);
  }
  calculateWorkMinutes(startDate, endDate) {
    const millisecondsDifference = endDate.getTime() - startDate.getTime();
    const totalMinutes = Math.round(millisecondsDifference / 60000);
    return totalMinutes;
  }
  async prevWeek() {
    try {
      if (!this.weeks || !this.weeks.length) {
        this.weeks = this.getWeeksInYear(new Date().getFullYear());
        this.currentWeekIndex = this.getCurrentWeekIndex();
      }

      if (this.currentWeekIndex !== 0) {
        this.currentWeekIndex--;
      } else {
        // Load previous year's weeks
        const prevYear = this.weeks[0].end.getFullYear() - 1;
        this.weeks = this.getWeeksInYear(prevYear);
        this.currentWeekIndex = this.weeks.length - 1;
      }

      this.currentWeek = this.weeks[this.currentWeekIndex];
      this.formService.previousWeekIndexAanwezigsheidslijst = this.currentWeekIndex;

      this.setDatesOnUsers();

      this.isLoaded = false;
      await this.getDagrapportenOfWeek(this.currentWeek.start, this.currentWeek.end);
      this.isLoaded = true;
    } catch (error) {
      console.error('Error navigating to previous week:', error);
    }
  }

  async nextWeek() {
    try {
      if (!this.weeks || !this.weeks.length) {
        this.weeks = this.getWeeksInYear(new Date().getFullYear());
        this.currentWeekIndex = this.getCurrentWeekIndex();
      }

      if (this.currentWeekIndex !== this.weeks.length - 1) {
        this.currentWeekIndex++;
      } else {
        // Load next year's weeks
        const nextYear = this.weeks[this.weeks.length - 1].start.getFullYear() + 1;
        this.weeks  = this.getWeeksInYear(nextYear);
        this.currentWeekIndex = 0;
      }

      this.currentWeek = this.weeks[this.currentWeekIndex];
      this.formService.previousWeekIndexAanwezigsheidslijst = this.currentWeekIndex;

      this.setDatesOnUsers();

      this.isLoaded = false;
      await this.getDagrapportenOfWeek(this.currentWeek.start, this.currentWeek.end);
      this.isLoaded = true;
    } catch (error) {
      console.error('Error navigating to next week:', error);
    }
  }

// Helper function to get current week index
  private getCurrentWeekIndex(): number {
    const today = new Date();
    return this.weeks.findIndex(week =>
      today >= week.start && today <= week.end
    ) || 0;
  }
  convertMinutenToUren(werkMinuten: number) {
    return Math.floor(werkMinuten / 60) + 'u' + ('0' + (werkMinuten % 60)).slice(-2);
  }

  goToDagrapport(day: any, activiteit: any) {
    this.formService.previousUserIdAanwezigheidslijst = day.userId;
    if(day.transportDagrapportId){
      this.formService.comingFromAanwezigheidslijst = true;
      this.formService.comingFromMeerwerken = false;
      this.router.navigate(['/pages/transport-dagrapporten/edit-transport-dagrapport' , day.transportDagrapportId]);
    } else {
      this.formService.comingFromAanwezigheidslijst = true;
      this.formService.comingFromMeerwerken = false;
      this.router.navigate(['/pages/dagrapporten/editdagrapport' ,activiteit.dagrapportId]);
    }
  }
  goToTransportDagrapport( transportDagrapportId: string) {
    this.formService.comingFromAanwezigheidslijst = true;
      this.router.navigate(['/pages/edit-transport-dagrapport' , transportDagrapportId]);
  }

  choseSoort($event: any) {
    let soort = $event;

    if(soort === 'Arbeiders'){
      this.filteredArray = this.arbeiders;
    } else if(soort === 'Chauffeurs'){
      this.filteredArray = this.chauffeurs;
    } else if(soort === 'Onderaannemers'){
      this.filteredArray = this.onderaannemers;
    }
    this.formService.aanwezigheidsLijstChosenSoort = soort;
    this.filterItemsNaam = this.filteredArray;
    this.observableFilteredItems$ = of(this.filteredArray?.map(x => x.name));
    this.input.nativeElement.value = '';
    if (this.scrollContainer && this.scrollContainer.nativeElement) {
      this.scrollContainer.nativeElement.scrollTop = 0;
    }
  }
  delay(timeInMillis: number): Promise<void> {
    return new Promise((resolve) => setTimeout(() => resolve(), timeInMillis));
  }

  clearAutocomplete() {
    this.input.nativeElement.value = '';
    this.filterItemsNaam = this.filteredArray;
    this.observableFilteredItems$ = of(this.filterItemsNaam.map(x => x.name));
    this.input.nativeElement.focus();
  }

  clickAutoCompleteUser($event) {
    let naam = $event;
    const userIndex = this.filteredArray.findIndex(user => user.name === naam);
    if (userIndex !== -1) {
      this.scrollToUser(userIndex);

    }

  }

  private scrollToUser(userIndex: number) {
    setTimeout(() => {
      const userElementArray = this.userElements.toArray();
      if (userElementArray[userIndex]) {
        const userElement = userElementArray[userIndex].nativeElement;
        const container = this.scrollContainer.nativeElement;

        const userRect = userElement.getBoundingClientRect();
        const containerRect = container.getBoundingClientRect();

        const relativeTop = userRect.top - containerRect.top + container.scrollTop;

        this.smoothScrollTo(container, relativeTop, 150);
      }
    }, 100); // Small delay to ensure DOM is ready
  }

  private filter(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.filterItemsNaam.filter(optionValue => optionValue.name.toLowerCase().includes(filterValue)).map(optionValue => optionValue.name);
  }

  getFilteredOptions(value: string): Observable<string[]> {
    return of(value).pipe(
      map(filterString => this.filter(filterString)),
    );
  }
  onChange() {
    this.observableFilteredItems$ = this.getFilteredOptions(this.input.nativeElement.value);
  }


  private smoothScrollTo(element: HTMLElement, to: number, duration: number) {
    const start = element.scrollTop;
    const change = to - start;
    const increment = 20;
    let currentTime = 0;

    const animateScroll = () => {
      currentTime += increment;
      const val = this.easeInOutQuad(currentTime, start, change, duration);
      element.scrollTop = val;
      if (currentTime < duration) {
        requestAnimationFrame(animateScroll);
      }
    };
    animateScroll();
  }

  private easeInOutQuad(t: number, b: number, c: number, d: number): number {
    t /= d / 2;
    if (t < 1) return c / 2 * t * t + b;
    t--;
    return -c / 2 * (t * (t - 2) - 1) + b;
  }
}
