<div class="setup-guide">
  <div class="setup-guide__header">
    <h1>Handleiding minimale instellingen modules</h1>
    <h4>*Voor een optimale installatie houdt u best deze volgorde aan.</h4>
  </div>

  <div class="setup-guide__steps">
    <div *ngFor="let step of steps" class="setup-guide__step">
      <div class="step__header">
        <h2 class="step__title">{{ step.title }} <i style="font-size: 24px;color:green;" class="fa fa-circle-check" *ngIf="step.imageInTitle" > </i></h2>
      </div>

      <div class="step__content">
        <p *ngIf="step.content">{{ step.content }}</p>

        <div *ngIf="step.modules" class="module-boxes">
          <div *ngFor="let module of step.modules"
               class="module-box"
               [ngClass]="getModuleClass(module)">
            <i [class]="getModuleIcon(module)"></i>
            <span>{{ module }}</span>
          </div>
        </div>


        <ul *ngIf="step.bullets" class="step__bullets">
          <li *ngFor="let bullet of step.bullets">{{ bullet }}</li>
        </ul>


        <div class="imageRow">
          <img  *ngIf="step.image1" [src]="step.image1" [alt]="step.title"
                class="small-image">
          <i *ngIf="step.image2" class="fas fa-arrow-right arrowIcon"></i>
          <img *ngIf="step.image2" [src]="step.image2" [alt]="step.title"
               class="small-image">
        </div>
        <div style="padding-left: 35px" *ngIf="step.image3" class="imageRow">
          <i class="fas fa-arrow-right arrowIcon"></i>
          <img  [src]="step.image3" [alt]="step.title"
               class="small-image">
        </div>

    </div>
  </div>
</div>
