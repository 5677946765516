<nb-spinner *ngIf="isSaving" class="opslaan-spinner" size="giant" [message]="'Opslaan..'" status="info"></nb-spinner>

<h1 class="custom-title"><i class="fa-solid fa-gear bigFaIcon"></i>Algemene instellingen</h1>
<nb-card style="max-width:1000px;"  class="scroll-table2">
  <form *ngIf="isLoaded" [formGroup]="settingsForm" (ngSubmit)="onSubmit()" class="specialShadow add-form">
    <div class="firstDiv">
    <table>
      <thead>
        <tr>
          <th></th>
          <th></th>
        </tr>
      </thead>
      <tbody>
          <tr style="height:60px; margin-top:15px;">
            <td style="width:230px;">Standaard startuur</td>
            <td>
              <nb-select class="smallInput" formControlName="startUur">
                <nb-option *ngFor="let uur of uren" value="{{ uur}}">{{ uur }}
                </nb-option>
              </nb-select>
              <span style="font-size:15px; font-weight:bold; margin-left:6px;margin-right: 10px">uur</span>
            </td>
            <td>
              <nb-select class="smallInput" formControlName="startMinuten">
                <nb-option *ngFor="let minuut of minuten" value="{{ minuut}}">{{ minuut }}
                </nb-option>
              </nb-select>
              <span style="font-size:15px; font-weight:bold; margin-left:6px">min</span>
            </td>
          </tr>
          <tr style="height:60px">
            <td style="width:230px;">Standaard einduur</td>
            <td>
              <nb-select class="smallInput" formControlName="eindUur">
                <nb-option *ngFor="let uur of uren" value="{{ uur}}">{{ uur }}
                </nb-option>
              </nb-select>
              <span style="font-size:15px; font-weight:bold; margin-left:6px;margin-right: 10px">uur</span>
            </td>
            <td>
              <nb-select class="smallInput" formControlName="eindMinuten">
                <nb-option *ngFor="let minuut of minuten" value="{{ minuut}}">{{ minuut }}
                </nb-option>
              </nb-select>
              <span style="font-size:15px; font-weight:bold; margin-left:6px">min</span>
            </td>
          </tr>
          <tr style="height:60px">
            <td style="width:230px;">Na toevoegen extra activiteit aan dagrapport in mobiele app: <br> Startuur volgende activiteit beginnend van einduur vorige activiteit</td>
            <td><nb-checkbox class="checkbox" formControlName="extraWerkFromPreviousEindUur"></nb-checkbox></td>
          </tr>
          <tr *ngIf="myCompany.heeftKostenBerekening">
            <td class="leftText">Uurloon chauffeurs</td>
            <td colspan="2">
              <i style="margin-right: 6px" class="fa fa-euro-sign"></i>
              <input class="smallInput" nbInput id="name" type="number" formControlName="uurLoonChauffeur" />
            </td>
          </tr>


      <tr style="margin-top:50px;">
        <td colspan="2">
          <button (click)="goToVerlofdagenInstellen()" mat-raised-button class="verlofdagen-button" type="submit">
            Verlofdagen instellen ->
          </button>
        </td>
      </tr>-
      </tbody>
    </table>
   <div style="display:flex;flex-direction:column;justify-content: space-between;align-items: center; width:45%;margin-top:20px;">
     <div style="margin-left:25px;display:flex;flex-direction:row; width:430px;justify-content:space-between; align-items:center;margin-bottom:15px">
       <p style="margin-top:11px">Mogelijke eenheden <br>dagrapportmdodule</p>
       <nb-select multiple style="width:210px;" formControlName="eenheden">
         <nb-option value="m³">m³</nb-option>
         <nb-option value="ton">ton</nb-option>
         <nb-option value="lm">lm</nb-option>
         <nb-option value="m²">m²</nb-option>
         <nb-option value="stuk">stuks</nb-option>
       </nb-select>
     </div>
     <div style="margin-left:25px;display:flex;flex-direction:row; width:430px;justify-content:space-between; align-items:center;margin-bottom:15px">
       <p style="margin-top:11px">Wanneer grondwerker dagrapport wilt beiïndigen -> <br>
         Overzichtslijst tonen met alle activiteiten van die dag  met bevestiging?
       </p><nb-checkbox class="checkbox" formControlName="heeftBeiindigOverzichtDagrapporten"></nb-checkbox>
     </div>
     <div style="margin-left:25px;display:flex;flex-direction:row; width:430px;justify-content:space-between; align-items:center;margin-bottom:15px">
       <p style="margin-top:11px">Foto's opslaan op GSM na foto nemen in mobiele app?</p>
       <nb-checkbox class="checkbox" formControlName="savedPhotosAlbum"></nb-checkbox>
     </div>
     <div style="margin: 1rem; border:1px solid black;border-radius:10px; max-width:400px !important; padding:10px;">
       <tr>
         <td style="min-width:120px !important;"><span style="text-decoration:underline">Huidige logo:</span> <br><img *ngIf="logo != null" style="max-width:120px; height:auto; margin-top:10px !important;" src="{{logo}}">
           <strong *ngIf="logo == null">Geen</strong></td>
         <td style="width:300px !important;padding-left:50px !important;">
           Logo vervangen?<br>
           <input type="file" name="file" (change)="onFileSelect($event)"
                  accept=".png,.jpg,.jpeg,.tiff" class="uploadButton" />
           <div class="uploadable-images-container">
             <div *ngFor="let image of chosenImageList">
               <img  style="max-width:200px; height:auto;" [src]="image" class="photo" />
             </div>
           </div>
         </td>
       </tr>
     </div>
   </div>
    </div>
    <div style="border:1px solid gray; max-width:540px !important; border-radius: 5px; margin:25px 0px 15px 15px">
    <div style="margin-left:25px;display:flex;flex-direction:row; width:500px;justify-content:space-between; align-items:center">
      <div class="notification-description">
        <h4>Popup-melding: Herinnering Dagrapport</h4>
        <p>Een popup-melding "Vergeet uw dagrapport niet in te vullen" verschijnt wanneer aan de volgende voorwaarden wordt voldaan:</p>
        <ul>
          <li>De gebruiker heeft in de afgelopen 2 dagen een dagrapport met activiteit geregistreerd (uitgezonderd maandag)</li>
          <li>De gebruiker heeft voor de huidige dag nog geen dagrapport aangemaakt</li>
        </ul>
      </div>
      </div>
      <table style="min-width:450px !important;">
        <thead>
        <tr>
          <th></th>
          <th></th>
        </tr>
        </thead>
        <tbody>
        <div style="margin: 1rem;">
          <tr style="height:60px;" >
            <td style="width:230px;">Popup-melding tijd</td>
            <td>
              <nb-select class="smallInput"  formControlName="popupUur">
                <nb-option *ngFor="let uur of popupUren" value="{{ uur}}">{{ uur }}
                </nb-option>
              </nb-select>
              <span style="font-size:15px; font-weight:bold; margin-left:6px">uur</span>
            </td>
            <td>
              <nb-select class="smallInput"  style="margin-left:10px" formControlName="popupMinuten">
                <nb-option *ngFor="let minuut of popupMinuten" value="{{ minuut}}">{{ minuut }}
                </nb-option>
              </nb-select>
              <span style="font-size:15px; font-weight:bold; margin-left:6px">min</span>
            </td>
          </tr>
        </div>
        </tbody>
      </table>
    </div>
    <button [disabled]="isSaving ||!hasChangedValue" type="submit"  mat-raised-button class="form-button-blue" color="primary">
      <div class="divInsideButton">
        <fa-icon class="normalIcon" [icon]="faFloppyDisk"></fa-icon>
        <span class="button-text">Opslaan</span></div>
    </button>
  </form>
</nb-card>
