import { NbMenuItem } from '@nebular/theme';
import { FormService} from "../services/form.service";

export const MENU_ITEMS: NbMenuItem[] = [
      {
        title: 'Overzichten',
        group: true
      },
      {
        title: 'Documenten genereren',
        link: '/pages/overzicht-dagrapporten',
        icon: { icon: 'file-download', pack: 'fa' },
      },
      {
        title: "Aanwezigheidslijst",
        link: '/pages/aanwezigheidslijst',
        icon: { icon: 'user-check', pack: 'fa' },
      },
      {
        title: 'Dagboek & staat der verlet',
        link: '/pages/staat-der-verletdagen',
        icon: { icon: 'book-open', pack: 'fa' },
      },
     {
        title: "Werfplanning",
        link: '/pages/werfplanning',
        icon: { icon: 'calendar-check', pack: 'fa' },
      },
      {
        title: 'Rapporten & meldingen',
        group: true
      },
      {
        title: 'Dagrapporten',
        link: '/pages/dagrapporten',
        home: true,
        icon: { icon: 'person-digging', pack: 'fa' }
      },
      {
        title: "Meerwerken",
        link: '/pages/dagrapporten/meerwerken',
        icon: { icon: 'file-circle-plus', pack: 'fa' },
      },
      {
        title: 'Reparaties',
        link: '/pages/reparaties',
        icon: { icon: 'screwdriver-wrench', pack: 'fa' },
      },
      {
        title: 'Taken magazijnier',
        link: '/pages/magazijn-taken',
        icon: {icon: 'warehouse', pack: 'fa'}
      },
      {
        title: 'Verlofaanvragen',
        link: '/pages/verlofs',
        icon: { icon: 'calendar-alt', pack: 'fa' },
      },
      {
        title: 'Schademeldingen',
        link: '/pages/schademeldingen',
        icon: {icon: 'triangle-exclamation', pack: 'fa'}
      },
      {
        title: 'Instellingen',
        group: true
      },
      {
        title: 'Personeel',
        link: '/pages/users',
        icon: { icon: 'users-line', pack: 'fa' },
      },
      {
        title: 'Materieel & werven',
        link: '/pages/materieel-instellen',
        icon: { icon: 'gears', pack: 'fa' },
      },
      {
        title: 'Andere instellingen',
        link: '/pages/settings',
        icon: { icon: 'gear', pack: 'fa' },
      },
      {
        title: 'Transport',
        group: true
      },
      {
        title: 'Transport dagrapporten',
        link: '/pages/transport-dagrapporten',
        icon: { icon: 'truck', pack: 'fa' },
      },
      {
        title: 'Afvalstof formulieren OVAM',
        link: '/pages/e-digidlist',
        icon: { icon: 'recycle', pack: 'fa' },
      },
      {
        title: 'Klanten',
        link: '/pages/klanten',
        icon: { icon: 'building', pack: 'fa' },
      },
];
