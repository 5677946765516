import {User} from "./user";
import {Company} from "./company";
import {DagboekDerWerken} from "./dagboek-der-werken";
import {DayWeatherData} from "./day-weather-data";

export class Werf {
  public _id: string;
  public naam: string;
  isArchived: boolean;
  nummer: string;
  public uurLoonPloegbaas: number;
  public uurLoonArbeider: number;
  public startUur: string;
  public eindUur: string;
  public werfleiders: User[];
  public isLaadLosPlaats: boolean;
  public logoOpdrachtgever1: string;
  public logoOpdrachtgever2: string;
  logoOpdrachtgever3: string;
  adres: string;
  latCoord: number;
  longCoord: number;
  uitvoeringsTermijn: number;
  startDatum: Date;
  gemeente: string;
  straat: string;
  huisNr: string;
  postcode: string;
  company_id: Company;
  projectNr: string;
  land: string;
  studieBureau: string;
  leidendAmbtenaar: string;
  gebiedsIngenieur: string;
  toezichter: string;
  toezichters: User[];
  uitgenodigdeToezichters: {name: string, email: string}[];
  sharedCompanys: Company[];
  selected: boolean;
  isActiefOpWerfPlanning: boolean;
  heeftKalenderDagen: boolean;

  //tempvar
  isWerfleiderOfThisWerf: boolean;
  latestDagboek: DagboekDerWerken;
  dagboekenAangevuldTot: Date;
  isNogNietGestart: boolean;
  weatherData: DayWeatherData;
  soort: string;
  extraInfoOverIsWeerverlet: string;


  constructor(naam: string,_id: string) {
    this.naam = naam;
    this._id = _id;
  }
}
