<div class="update-container">
  <p class="update-header">Laatste updates:</p>
  <p class="update-item"><i style="padding-right: 4px" class="fa fa-cloud-sun"></i>Verfijningen dagboek & staat der verlet</p>
  <p class="update-item"><i style="padding-right: 4px" class="fa fa-cloud-sun"></i>Dagboeken in kalenderdagen</p>
  <p class="update-item"><i style="padding-right: 4px" class="fa fa-cloud-sun"></i>Toezichter uitnodigen op platform voor digitale opvolging dagboeken</p>
  <p class="update-item"><i style="padding-right: 4px" class="fa fa-bell"></i>Handleidingen voor edigid afvalstoffen beschikbaar</p>
  <p class="update-item"><i style="padding-right: 4px" class="fa fa-map-location-dot"></i>Vanaf V24.6 wordt het adres van de werf overal bij de werfnaam getoond</p>
  <div class="version">Laatste versie mobiele app: V24.7  -  25/01/2025</div>
</div>
<div class="update-container">
  <p class="update-header">Aankomende functies:</p>
  <p class="update-item"><i style="padding-right: 4px" class="fa fa-cloud-sun"></i>Dagboek der werken toezichter communicatie & goedkeuring/afwijzing + vernieuwde dagboekpdf</p>
  <p class="update-item"><i style="padding-right: 4px" class="fa fa-circle-nodes"></i>Koppeling met API's van Grondbank & Grondwijzer in de transportmodule</p>
  <p class="update-item"><i style="padding-right: 3px" class="fa fa-sync-alt"></i><b>Doorlopende optimalisatie van alle modules</b></p>
</div>
