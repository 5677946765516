import {Component, OnInit, Output, EventEmitter, ElementRef, ViewChild, NgZone, Input} from '@angular/core';
import { UntypedFormBuilder, Validators, FormControl } from '@angular/forms';
import { NbToastrService } from '@nebular/theme';
import {map, startWith} from "rxjs/operators";
import {Company} from "../../../models/company";
import {ApiService} from "../../../services/api.service";
import {FormService} from "../../../services/form.service";
import {Router} from "@angular/router";

@Component({
  selector: 'ngx-add-new-company',
  templateUrl: './add-new-company.component.html',
  styleUrls: ['./add-new-company.component.scss']
})
export class AddNewCompanyComponent implements OnInit {
  addForm;
  public isLoaded: boolean;
  @Output() outputEvent: EventEmitter<string> = new EventEmitter();
  isSaving: boolean;
  hoogsteOvamId: string;
  constructor(
    private formBuilder: UntypedFormBuilder,
    private apiService: ApiService,
    private toastrService: NbToastrService,
    private formService: FormService,
    private router: Router
  ) {}

  ngOnInit(){
    if(!this.apiService.isAdmin){
      this.router.navigate(['/']);
    }
    this.apiService.getHighestOvamId().subscribe(x => {
      this.hoogsteOvamId = x as string;
      console.log(x)

    });
    this.addForm = this.formBuilder.group({
      name: ['', [Validators.required]],
      heeftKostenBerekening: [true],
      heeftReparatieModule: [true],
      heeftMagazijnierModule: [true],
      heeftSchademeldingsModule: [true],
      heeftVerlofAanvragen: [true],
      ovamId: [''],
      ookTestObjectenEnAccountsGenereren: [false],
      prijsInstellingen: this.formBuilder.group({
        dagrapportenModulePrijsPerArbeider: [3.25, [Validators.min(0)]],
        dagboekenModulePrijsPerArbeider: [3.25, [Validators.min(0)]],
        combinatieDagrapportenEnDagboekenModulePrijsPerArbeider: [5.7, [Validators.min(0)]],
        gewoneTransportModulePrijsPerChauffeur: [4.2, [Validators.min(0)]],
        reparatieModulePrijsPerOnderhoudstechnieker: [23, [Validators.min(0)]],
        magazijnierModulePrijsPerMagazijnier: [19, [Validators.min(0)]],
        verlofAanvragenModulePrijsPerGebruiker: [0.5, [Validators.min(0)]],
        ovamModulePrijsPerIdentificatieFormulier: [0.12, [Validators.min(0)]]
      })
    });

    this.isSaving = false;
    this.isLoaded = true;
  }

  async onSubmit(data) {
    if (!this.isSaving) {
      this.isSaving = true;
      let prijsInstellingen = data.prijsInstellingen;
      data.prijsInstellingen = undefined;
      let metTestObjectenEnAccounts = data.ookTestObjectenEnAccountsGenereren;
      data.ookTestObjectenEnAccountsGenereren = undefined;
      data.grondwerkerHeeftReparatie = data.heeftReparatieModule;
      data.werfleiderHeeftReparatie = data.heeftReparatieModule;
      data.grondwerkerHeeftMagazijnier = data.heeftMagazijnierModule;
      data.werfleiderHeeftMagazijnier = data.heeftMagazijnierModule;
      let sendObject = {company: data, prijsInstellingen: prijsInstellingen};
      this.apiService.addNewCompany(sendObject, metTestObjectenEnAccounts).subscribe(x => {
        console.log(x);
        this.toastrService.show('Bedrijf toegevoegd', 'Success', {status: 'success'});
      })
    }
  }

}
